<template>
  <b-nav-item @click="getMe">
    <span class="text-info">
      {{ $t('credits.credit') }}
      {{ userInfo.credit | currency }}
      <feather-icon
        icon="RefreshCcwIcon"
        size="14"
      />
    </span>
  </b-nav-item>
</template>

<script>
import useAppConfig from '@core/app-config/useAppConfig'
import { computed } from '@vue/composition-api'
import { BNavItem } from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    BNavItem,
  },
  setup() {
    const { skin } = useAppConfig()

    const isDark = computed(() => skin.value === 'dark')

    return { skin, isDark }
  },
  computed: {
    ...mapGetters(['userInfo']),
  },
  methods: {
    ...mapActions(['getMe']),
  },
}
</script>
